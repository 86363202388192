#dv2 {
  display: none;
}
.buttdv Button {
  margin-left: 70px;
}

#switch {
  margin-top: -15px;
}

@media only screen and (max-width: 414px) {
  #code {
    padding-left: -10px;
  }
  #raison {
    padding-right: 15px;
  }
  #annuler {
    padding-right: 25px;
  }
  #mont {
    padding-left: 0px;
    padding-right: 40px;
  }
  #vend {
    padding-right: 45px;
  }
  .buttdv Button {
    position: relative;
    left: 227px;
    bottom: 32px;
  }
  .buttdv {
    /* position: absolute; */
    width: 530px;
    height: 0;
  }
  .adddv {
    margin-left: 290px;
    margin-top: -40px;
  }
  #d {
    margin-left: 50px;
  }
  #dv1 {
    display: none;
  }
  #dv2 {
    display: flex;
    padding: 0;
    width: 100%;
  }
  Card.Body {
    padding: 0;
    width: 100%;
  }
  .rowdv {
    margin-top: 10px;
    margin-left: 26px;
    width: 100%;
  }
  InputBase {
    z-index: -99;
  }
}

@media only screen and (max-width: 394px) {
  #code {
    padding-left: -10px;
  }
  #raison {
    padding-right: 15px;
  }
  #annuler {
    padding-right: 25px;
  }
  #mont {
    padding-left: 0px;
    padding-right: 40px;
  }
  #vend {
    padding-right: 45px;
  }
  .buttdv Button {
    font-size: 11px;
    position: relative;
    left: 220px;
    bottom: 32px;
  }
  .buttdv {
    /* position: absolute; */
    width: 530px;
    height: 0;
  }
  .adddv {
    margin-left: 270px;
    margin-top: -40px;
  }
  #d {
    margin-left: 0px;
  }
  #dv1 {
    display: none;
  }
  #dv2 {
    display: flex;
    padding: 0;
    width: 100%;
  }
  Card.Body {
    padding: 0;
    width: 100%;
  }
  .rowdv {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
    justify-content: space-around;
  }
  InputBase {
    z-index: -99;
  }
  #lidv {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 375px) {
  #code {
    padding-left: -10px;
  }
  #raison {
    padding-right: 15px;
  }
  #annuler {
    padding-right: 25px;
  }
  #mont {
    padding-left: 0px;
    padding-right: 40px;
  }
  #vend {
    padding-right: 45px;
  }
  .buttdv Button {
    font-size: 11px;
    position: relative;
    left: 200px;
    bottom: 32px;
  }
  .buttdv {
    /* position: absolute; */
    width: 530px;
    height: 0;
  }
  .adddv {
    margin-left: 255px;
    margin-top: -40px;
  }
  #d {
    margin-left: 0px;
  }
  #dv1 {
    display: none;
  }
  #dv2 {
    display: flex;
    padding: 0;
    width: 100%;
  }
  Card.Body {
    padding: 0;
    width: 100%;
  }
  .rowdv {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
    justify-content: space-around;
  }
  InputBase {
    z-index: -99;
  }
  #lidv {
    margin-left: -15px;
  }
}

@media screen and (max-width: 1280px) {
  .buttdv {
    margin-left: -40px;
  }
  .buttdv Button {
    width: 190px;
  }
}

@media screen and (max-width: 1366px) {
  .buttdv {
    padding-left: 90px;
  }
  .buttdv Button {
    margin-left: -45px;
  }
}
