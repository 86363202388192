#bf2 {
  display: none;
}

@media screen and (max-width: 700px) {
  #bf1 {
    display: none;
  }
  #bf2 {
    display: flex;
  }
  .rowbf {
    position: relative;
    left: 25px;
  }
  #bf {
    position: relative;
    left: 25px;
  }
}
