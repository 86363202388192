.sidebar {
  /* height: 1100px; */
  height: 100vh;
  position: relative;
  width: 100%;
}
.pl-5 {
  display: none;
}

@media only screen and (max-width: 700px) {
  .sidebar {
    height: 100%;
    position: relative;
    width: 600px;
  }
  .pl-5 {
    display: flex;
  }
  .pl-4 {
    display: none;
  }
}
