.buttbs {
  padding-left: 190px;
}
#bs2 {
  display: none;
}

@media screen and (max-width: 1366px) {
  .buttbs {
    padding-left: 125px;
  }
}

@media screen and (max-width: 1280px) {
  .buttbs {
    padding-left: 105px;
  }
}

@media screen and (max-width: 700px) {
  #codart {
    font-size: 15px;
    font-weight: bolder;
  }
  .buttbs {
    height: 0;
  }
  .buttbs Button {
    position: relative;
    left: 95px;
    bottom: 32px;
  }
  #bs2 {
    display: flex;
  }
  #bs1 {
    display: none;
  }
  #bs {
    margin-left: 50px;
  }
  .rowbs {
    margin-top: 10px;
    margin-left: 26px;
    width: 100%;
    flex-wrap: nowrap;
  }
  .addbs {
    margin-left: 290px;
    margin-top: -40px;
  }
}
