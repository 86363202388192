.buttregf {
  padding-left: 127px;
}

@media screen and (max-width: 1366px) {
  .buttregf {
    padding-left: 48px;
  }
}

@media screen and (max-width: 1280px) {
  .buttregf {
    padding-left: 27px;
  }
}

@media screen and (max-width: 700px) {
  .buttregf {
    height: 0;
  }
  .buttregf Button {
    position: relative;
    font-size: 11px;
    left: 133px;
    bottom: 33px;
  }
}
