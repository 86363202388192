#bc2 {
  display: none;
}

.buttbc Button {
  padding-left: 35px;
}

.buttbc {
  margin-left: 115px;
}

#cat {
  margin-top: -20px;
}

#switch {
  margin-top: -15px;
}


@media only screen and (max-width: 414px) {
  #num {
    padding-left: 15px;
  }
  #annuler {
    margin-right: -10px;
  }
  #mont {
    margin-left: 15px;
  }
  #pj {
    padding-right: 27px;
  }

  .buttbc Button {
    position: relative;
    bottom: 32px;
    left: 137px;
  }
  .buttbc {
    height: 0;
  }

  .addbc {
    margin-left: 290px;
    margin-top: -40px;
  }
  #b {
    margin-left: 50px;
  }
  #bc1 {
    display: none;
  }
  #bc2 {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .rowbc {
    margin-top: 10px;
    margin-left: 26px;
    width: 100%;
  }
}

@media only screen and (max-width: 394px) {
  #num {
    padding-left: 15px;
  }
  #annuler {
    margin-right: -10px;
  }
  #mont {
    margin-left: 15px;
  }
  #pj {
    padding-right: 27px;
  }

  .buttbc Button {
    font-size: 11px;
    position: relative;
    bottom: 32px;
    left: 134px;
  }
  .buttbc {
    height: 0;
  }

  .addbc {
    margin-left: 270px;
    margin-top: -40px;
  }
  #b {
    margin-left: 0px;
  }
  #bc1 {
    display: none;
  }
  #bc2 {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .rowbc {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }

  #libc {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 375px) {
  #num {
    padding-left: 15px;
  }
  #annuler {
    margin-right: -10px;
  }
  #mont {
    margin-left: 15px;
  }
  #pj {
    padding-right: 27px;
  }

  .buttbc Button {
    font-size: 11px;
    position: relative;
    bottom: 32px;
    left: 113px;
  }
  .buttbc {
    height: 0;
  }

  .addbc {
    margin-left: 255px;
    margin-top: -40px;
  }
  #b {
    margin-left: 0px;
  }
  #bc1 {
    display: none;
  }
  #bc2 {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .rowbc {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
  }

  #libc {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 1280px) {
  .buttbc Button {
    padding-left: 0px;
    width: 210px;
    margin-left: -15px;
  }

  .buttbc {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1366px) {
  .buttbc Button {
    padding-left: 0px;
    width: 210px;
  }

  .buttbc {
    margin-left: 65px;
  }
}
