.bg {
  width: 100%;
  height: 840px;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.carousel-control-next-icon {
  background-color: #555657;
  border-radius:50%
}
.carousel-control-prev-icon {
  background-color: #555657;
  border-radius:50%
}
.d-block {
  width: 100%;
  height: 640px;
  opacity: 90%;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.logo {
  width: 300px;
  height: 150px;
  margin-left: 95px;
}

.Lang {
  margin-bottom: 55px;
  display: flex;
  margin-top: -20px;
  justify-content: flex-end;
}

pml {
  color: white;
  font-size: 30px;
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow 2500ms linear infinite 2000ms;

  @keyframes reveal {
    80% {
      letter-spacing: 8px;
    }
    100% {
      background-size: 300% 300%;
    }
  }
}
.carousel-caption {
  top: 30%;
  bottom: auto;
}
.carousel-resp {
  display: none;
}
.logo-res {
  display: none;
}
.Lang-res {
  display: none;
}

@keyframes glow {
  40% {
    text-shadow: 0 0 8px #fff;
  }
}

@media screen and (max-width: 600px) {
  
  /* .carousel-control-next-icon {
    background-color: #555657;
    border-radius:50%
  }
  .carousel-control-prev-icon {
    background-color: #555657;
    border-radius:50%
  } */


  .carousel-resp {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 550px;
    left: 50px;
  }
  .logo-res {
    display: flex;
    flex-direction: column;
  }
  .log-in {
    height: 1200px;
  }
  .Lang {
    display: none;
  }
  .Lang-res {
    display: flex;
    margin-top: -20px;
    justify-content: flex-end;
    margin-bottom: 55px; 
    margin-left: -20px; 
  }
}
.password-icon {
  position: absolute;

  color: #007BFF;
  font-size: 18px;
  right: 55px;
  cursor: pointer;
  }

