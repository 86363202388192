.home-page {
  height: 88%;
  overflow-y: scroll;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #c7c7c7db;
}
.graphe {
  border-radius: 20px;
  margin-top: 20px;
}

.buttonDarck{
  color : #fff;
  border: 1px solid #fff;
  background: #0f2535
}
.td{
  color : #0f2535;
}
.tdDarck{
  color : #fff;
}
svg {
  border-radius: 20px;
}

.nav-link.active {
  // border-bottom : none;
  color: #fff !important;
  background-color: #172431 !important;
}


.nav-link {
  // border-bottom : none;
  color: rgb(138, 138, 138);

}

.card1 {
  // background-image: linear-gradient(to right, #34b0c8, #00d4ff);
  background-image: linear-gradient(
    to right,
    rgb(220, 0, 78),
    rgb(255, 161, 161)
  );

  transition: all 0.2s;
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  height: 60px;
  margin-bottom: 5px;
}

.card2 {
  background-image: linear-gradient(to right, #717171, #b4b4b4);
  transition: all 0.2s;
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  margin-bottom: 5px;
  height: 60px;
}

.card3 {
  background-image: linear-gradient(
    -20deg,
    #2b5876 0%,
    #4e4376 100%
  ) !important;
  transition: all 0.2s;
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  margin-bottom: 5px;
  height: 60px;
}

.card4 {
  // background-image: linear-gradient(
  //   to top,
  //   #0ba360 0%,
  //   #3cba92 100%
  // ) !important;
  background-image: linear-gradient(to right, #34b0c8, #00d4ff);
  transition: all 0.2s;
  border-radius: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  margin-bottom: 5px;
  height: 60px;
}

.card5 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
}

.card6 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
  width: 95%;
}

.card7 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(90deg, #3f5efb 0%, #fc466b 100%);
}

.card8 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
}

.card9 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(90deg, #45b649 0%, #dce35b 100%);
}

.card0 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
}

.card10 {
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  background-image: -webkit-linear-gradient(
    90deg,
    rgba(255, 0, 0, 0.2) 0%,
    rgba(0, 0, 255, 0.2) 100%
  );
  width: 95%;
}

.card11 {
  // background-image: -webkit-linear-gradient(to right, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, 0.6) 100%);
  //  background-image: linear-gradient(to top,  #0F2535 , #08052B      );
  background: #0f2535;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  // transition: all .2s;
  border-radius: 10px;
}

.card111 {
  // background-image: -webkit-linear-gradient(to right, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, 0.6) 100%);
  //  background-image: linear-gradient(to top,  #0F2535 , #08052B      );
  // background: #0f2535;
  background: #ffffff;

  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  // transition: all .2s;
  height: 100%;
  border-radius: 10px;
}

.card111Dark {
  // background-image: -webkit-linear-gradient(to right, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, 0.6) 100%);
  //  background-image: linear-gradient(to top,  #0F2535 , #08052B      );
  // background: #0f2535;
  background: #172431;

  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border: 2px solid #fff;
  // transition: all .2s;
  height: 100%;
  border-radius: 10px;
}

.scroll {
  margin: 4px, 4px;
  padding: 4px;
  // background-color: green;
  //width: 200px;
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: justify;
}

.card55 {
  // background-image: -webkit-linear-gradient(to right, rgba(255, 0, 0, 0.6) 0%, rgba(0, 0, 255, 0.6) 100%);
  //  background-image: linear-gradient(to top,  #0F2535 , #08052B      );
  background: rgb(255, 255, 255);
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border-width: 0;
  transition: all 0.2s;
  border-radius: 10px;
}

.buttonside {
  background-image: linear-gradient(-20deg, #0f2535, #08052b 100%) !important;
}

.p1 {
  font-size: 12px;
  color: #0f2535;
  font-weight: bold;
}

.p1Darck {
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}

.pb {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
}

.p2 {
  color: #8d4ed5;
  font-size: 18px;
  margin-top: -5px;
  //margin-bottom: 5px;
}

.p2Darck {
  color: #ffffff;
  font-size: 18px;
  margin-top: -5px;
  //margin-bottom: 5px;
}

.p3 {
  color: #4cca7a;
  margin-top: 15px;
}

.p4 {
  color: #6610f2;
  font-size: 12px;
  font-weight: bold;
  //  margin-top: -15px;
  //margin-bottom: -5px;
}

.p4Darck {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  //  margin-top: -15px;
  //margin-bottom: -5px;
}

.divider {
  background-color: white;
  font-size: 28px;
}

.col {
  padding-left: 5px;
  padding-right: 5px;
}

.col1 {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 450px),
  (min-device-width: 368px) and (max-device-width: 524px) {
  .card11 {
    width: 300px;
    margin: 5px;
  }
  .col1 {
    display: none;
  }
}

@media only screen and (max-width: 800px) and (min-width: 400px) {
  .col {
    // padding-left: 50px;
    // padding-right: 50px;
    //  display: none;
    // margin-left: 50px;
    margin-right: 50px;
    margin-top: 5px;
  }
  .col1 {
    display: none;
  }
  .card11 {
    width: 400px;
  }
  .roo {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .tabtopp {
    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }
    tr:nth-of-type(even) {
      background: #eee;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50%;
      width: auto;
      font-size: 12px;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    /*
      Label the data
      */
    td:nth-of-type(1):before {
      content: "Code";
    }
    td:nth-of-type(2):before {
      content: "Raison sociale";
    }

    td:nth-of-type(3):before {
      content: "Montant";
    }
  }
}

// .tabtopp {
//   tbody {
//     overflow-y: auto;
//     display: block;
//     max-height: 25em;
//   }
// }
