.buttbl Button {
  padding-left: 40px;
}

.buttbl {
  margin-left: 118px;
}

#bl2 {
  display: none;
}
@media screen and (max-width: 414px) {
  .buttbl {
    height: 0;
  }
  .buttbl Button {
    position: relative;
    bottom: 32px;
    left: 130px;
  }
  .addbl {
    margin-left: 290px;
    margin-top: -40px;
  }
  #bl {
    margin-left: 50px;
  }
  #bl1 {
    display: none;
  }
  #bl2 {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .rowbl {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 26px;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
}

@media screen and (max-width: 375px) {
  .buttbl {
    height: 0;
  }
  .buttbl Button {
    font-size: 11px;
    position: relative;
    bottom: 32px;
    left: 100px;
  }
  .addbl {
    margin-left: 240px;
    margin-top: -40px;
  }
  #bl {
    margin-left: 8px;
  }
  #bl1 {
    display: none;
  }
  #bl2 {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .rowbl {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
    flex-wrap: nowrap;
  }
  #libl {
    margin-left: -15px;
  }
}

@media screen and (max-width: 394px) {
  .buttbl {
    height: 0;
  }
  .buttbl Button {
    font-size: 12px;
    position: relative;
    bottom: 32px;
    left: 120px;
  }
  .addbl {
    margin-left: 260px;
    margin-top: -40px;
  }
  #bl {
    margin-left: 8px;
  }
  #bl1 {
    display: none;
  }
  #bl2 {
    display: flex;
    padding: 0;
    width: 100%;
  }

  .rowbl {
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0px;
    width: 100%;
    flex-wrap: nowrap;
  }
  #libl {
    margin-left: -15px;
  }
}

@media screen and (max-width: 1280px) {
  .buttbl Button {
    padding-left: 0px;
    margin-left: -80px;
  }

  .buttbl {
    margin-left: 0px;
    padding-left: 90px;
  }
}

@media screen and (max-width: 1366px) {
  .buttbl Button {
    padding-left: 0px;
    width: 210px;
  }

  .buttbl {
    margin-left: 65px;
  }
}
