.inline {
  margin: 30px 0;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1500px;
  max-height: 480px;
}

.bolck {
  margin: 50px;
  width: 18rem;
  height: 500px;
}

.textalign {
  text-align: center;
}

.trheight {
  height: 2px;
}

.fonttext {
  font-family: "Poppins", sans-serif;
}

.title-liste-devis {
  margin: 10px;
  font-family: "Poppins", sans-serif;
}

.Table {
  height: 350px; /* Just for the demo          */
  overflow-y: scroll;
  /* Trigger vertical scroll    */
  overflow-x: hidden; /* Hide the horizontal scroll */
}
.tabsStyle1 {
  height: 220px;
  /* Just for the demo          */
  /* overflow-y: scroll; */
}

.tabsStyle2 {
  height: 220px;
  /* Just for the demo          */
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
}

.tabledevis2 {
  width: 700px;
  background-color: beige;
  text-align: center;
  border: 2px solid black;
}
.trdevis2 {
  border: 2px solid black;
}
.tabledevis22 {
  width: 470px;
  height: 150px; /* Just for the demo          */
  overflow-y: scroll;
  overflow-x: scroll;
}

.btnadddevis {
  border-radius: 50%;
  font-weight: bold;
  font-size: 20px;
}

.footerinput {
  height: 20px;
  width: 80px;
  /* border-radius: 20%; */
  position: absolute;
  right: 10px;

  /* background-color: black; */
}

.inline1 {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footercards {
  width: 285px;
  height: 150px;
  /* background-color:  green; */
  color: black;
  background-color: rgba(0, 0, 0, 0.1);
  /* background-color: #ced4da; */
}

.footercards2 {
  width: 250px;
  height: 75px;
  /* background-color:  green; */
  color: white;
}

.inline2 {
  /* margin: 30px 0; */
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #495057;
}

.Table1 {
  height: 200px; /* Just for the demo          */
  overflow-y: scroll;
  /* Trigger vertical scroll    */
  overflow-x: hidden; /* Hide the horizontal scroll */
}

.DevisClientTable {
  text-align: center;
  height: 380px;
  overflow-y: scroll;
}

.Thead {
  background-color: #ced4da;
  color: #495057;
  height: 50px;
}

.btndevismodal {
  width: 250px;
  background-color: #020f64;
  color: white;
  font-weight: bold;
}
.btnligmodal {
  width: 250px;
  background-color: #e26700;
  color: white;
  font-weight: bold;
  border-color: #e26700;
}
.orangetext {
  color: #e26700;
}
.bold {
  font-weight: bold;
}
.bootstrapinput {
  background-clip: padding-box;
  background-color: rgb(255, 255, 255);
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 4.8px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0px;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(206, 212, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgb(206, 212, 218);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(206, 212, 218);
  border-top-left-radius: 0px;
  border-top-right-radius: 4.8px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(73, 80, 87);
  cursor: text;
  display: block;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  height: 48px;
  letter-spacing: normal;
  line-height: 30px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  min-width: 0px;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  position: relative;
  text-align: start;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  transition-delay: 0s, 0s;
  transition-duration: 0.15s, 0.15s;
  transition-property: border-color, box-shadow;
  transition-timing-function: ease-in-out, ease-in-out;
  width: 159.156px;
  word-spacing: 0px;
  writing-mode: horizontal-tb;
  -webkit-appearance: none;
  -webkit-rtl-ordering: logical;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.width-input {
  width: 250px;
}

.width-td {
  width: 10%;
}

.tr-text {
  text-align: center;
  font-size: 13px;
}

.client-devis-card {
  max-width: 340px;
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* /////////////////////////////////////////////// btn hover me */

.aa {
  display: block;
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-family: sans-serif;
  text-decoration: none;
  color: #333;
  border: 2px solid #333;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all 0.35s;
}

.aa span {
  position: relative;
  z-index: 2;
}

.aa:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #ff003b;
  transition: all 0.35s;
}

.aa:hover {
  color: #fff;
}

.aa:hover:after {
  width: 100%;
}

.txt {
  color: #fff;
}

.inlinelig {
  margin: 30px 0;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.TableArticle {
  height: 150px; /* Just for the demo          */
  overflow-y: scroll;
  /* Trigger vertical scroll    */
  /* overflow-x: hidden;  Hide the horizontal scroll */
}
