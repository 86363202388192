.buttbscam Button {
  margin-left: 121px;
}

@media only screen and (max-width: 1366px) {
  .buttbscam Button {
    margin-left: 51px;
  }
}

@media only screen and (max-width: 1280px) {
  .buttbscam Button {
    margin-left: 29px;
  }
}

@media only screen and (max-width: 414px) {
  .buttbscam {
    height: 0;
  }
  .buttbscam Button {
    position: relative;
    left: 146px;
    bottom: 35px;
  }
}

@media only screen and (max-width: 394px) {
  .buttbscam Button {
    font-size: 13px;
    position: relative;
    left: 136px;
  }
}

@media only screen and (max-width: 375px) {
  .buttbscam Button {
    font-size: 12px;
    position: relative;
    left: 117px;
  }
}
