.buttbe {
  padding-left: 200px;
}
#be2 {
  display: none;
}

@media screen and (max-width: 1366px) {
  .buttbe {
    padding-left: 132px;
  }
}

@media screen and (max-width: 1280px) {
  .buttbe {
    padding-left: 111px;
  }
}

@media screen and (max-width: 700px) {
  .buttbe {
    height: 0;
  }
  .buttbe Button {
    position: relative;
    bottom: 32px;
    left: 95px;
  }
  .addbe {
    margin-left: 290px;
    margin-top: -40px;
  }
  #be1 {
    display: none;
  }
  #be2 {
    display: flex;
  }
  .rowbe {
    margin-left: 26px;
    width: 100%;
    flex-wrap: nowrap;
  }
  #be {
    margin-left: 50px;
  }
}
