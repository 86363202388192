#av2 {
  display: none;
}

@media screen and (max-width: 600px) {
  #av1 {
    display: none;
  }
  #av2 {
    display: flex;
    padding: 0;
    width: 100%;
  }
  .rowav {
    margin-top: 10px;
    margin-left: 6px;
    width: 100%;
    flex-wrap: nowrap;
  }
  #av {
    margin-left: 10px;
    margin-right: 10px;
  }
}
