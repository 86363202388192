.buttbrf Button {
  margin-left: 57px;
}
#brfr2 {
  display: none;
}

@media screen and (max-width: 1366px) {
  .buttbrf Button {
    width: 320px;
    position: relative;
    right: 125px;
  }
}

@media screen and (max-width: 1280px) {
  .buttbrf Button {
    position: relative;
    right: 140px;
  }
}

@media only screen and (max-width: 700px) {
  #raiso {
    padding-left: 15px;
  }
  #pj {
    padding-left: 20px;
  }
  #mont {
    margin-right: 7px;
  }
  .buttbrf {
    height: 0px;
  }
  .buttbrf Button {
    position: relative;
    left: 90px;
    bottom: 33px;
  }
  #brfr1 {
    display: none;
  }
  #brfr2 {
    display: flex;
  }
  .rowbrf {
    position: relative;
    left: 25px;
  }
  #brf {
    position: relative;
    left: 25px;
  }
}
