.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #f8f8f8ad !important;
}
.loader {
  text-align: center;
  left: 50%;
  top: 20%;
  z-index: 9999;
  position: absolute;
}
