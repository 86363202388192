.buttff {
  padding-left: 190px;
}

#ff2 {
  display: none;
}

@media screen and (max-width: 1366px) {
  .buttff {
    padding-left: 120px;
  }
}

@media screen and (max-width: 1280px) {
  .buttff {
    padding-left: 98px;
  }
}

@media screen and (max-width: 700px) {
  .buttff {
    height: 0px;
  }
  .buttff Button {
    position: relative;
    left: 95px;
    bottom: 33px;
  }

  #ff1 {
    display: none;
  }

  #ff2 {
    display: flex;
  }

  .rowff {
    margin-left: 20px;
    flex-wrap: nowrap;
  }

  #ff {
    margin-left: 35px;
  }

  .addff {
    margin-top: -42px;
    margin-left: 290px;
  }
}
