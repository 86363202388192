.buttcli {
  padding-left: 85px;
}
.buttfrs {
  padding-left: 155px;
}
#barre {
  margin-bottom: -21px;
}

.MuiBreadcrumbs-separator {
  color : rgb(190, 190, 190)
}

/* tbody {
  background-color: green ;
  display: block;
} */
.modal-header>button {
  color :rgb(207, 207, 207) 
}



tr:nth-child(2n) { background-color: rgb(190, 190, 190) !important; }


@media screen and (max-width: 700px) {
  .buttcli {
    height: 0;
  }
  .buttcli Button {
    position: relative;
    left: 228px;
    bottom: 32px;
  }
  .buttfrs {
    height: 0;
  }
  .buttfrs Button {
    position: relative;
    left: 95px;
    bottom: 33px;
  }
}

@media screen and (max-width: 1280px) {
  .buttcli Button {
    padding-left: 0;
  }
  .buttcli {
    margin-left: -5px;
  }
  .buttfrs Button {
    margin-left: -25px;
  }
  
}

@media screen and (max-width: 1366px) {
  .buttcli {
    padding-left: 0px;
    padding-right: 0;
  }
  .buttcli Button {
    margin-left: 45px;
  }
  .buttfrs {
    padding-left: 90px;
  }

}
