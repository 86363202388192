.buttbcfrs {
  padding-left: 44px;
}

#bcfr2 {
  display: none;
}

@media only screen and (max-width: 1366px) {
  .buttbcfrs Button {
    width: 320px;
    position: relative;
    right: 110px;
  }
}

@media only screen and (max-width: 1280px) {
  .buttbcfrs {
    padding-left: 28px;
  }
}

@media only screen and (max-width: 700px) {
  .buttbcfrs {
    height: 0;
  }
  .buttbcfrs Button {
    position: relative;
    left: 120px;
    bottom: 33px;
  }
  #bcfr1 {
    display: none;
  }
  #bcfr2 {
    display: flex;
  }
  .rowbcf {
    position: relative;
    left: 25px;
  }
  #bcf {
    position: relative;
    left: 25px;
  }
}
