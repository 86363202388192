.title {
  font-size: 16px;
  margin-bottom: 20px;
}

li {
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.text_menu {
  font-family: Helvetica, sans-serif;
  font-size: 13px;
}
a.active {
  // color: #3f95d9;
  color: #b8d0e8;
  // color: #65a9f1;
  // color: rgb(63, 81, 181);
}
