.buttregc {
  padding-left: 165px;
}

@media screen and (max-width: 1366px) {
  .buttregc {
    padding-left: 86px;
  }
}

@media screen and (max-width: 1280px) {
  .buttregc {
    padding-left: 65px;
  }
}

@media screen and (max-width: 700px) {
  .buttregc {
    height: 0px;
  }
  .buttregc {
    left: 95px;
    bottom: 33px;
  }
}
