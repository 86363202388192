.buttart {
  padding-left: 0px;
}

@media screen and (max-width: 1366px) {
  .buttart {
    padding-left: 0;
  }
  .buttart Button {
    position: relative;
    left: 134px;
  }
}

@media screen and (max-width: 1280px) {
  .buttart Button {
    position: relative;
    left: 115px;
  }
}

@media screen and (max-width: 760px) {
  .buttart {
    height: 0;
  }
  .buttart Button {
    position: relative;
    left: 20px;
  }
}
