.App {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.MuiSvgIcon-root{
  color: #CCCCCC
 }

body {
  font-family: "Nunito Sans", sans-serif;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  background: #fafafa;
}

@import "https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap";

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 210px;
  max-width: 210px;
  background-image: linear-gradient(-20deg, #0f2535, #1b2430) !important;
  min-height: 130vh;

  color: #fff;
  margin-left: -210px;
  transition: all 0.5s;
}
.list-unstyled {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  min-height: 100vh;
}


.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background-color: #232324;
  color: white;
}
.menu-open-hover:hover {
  background-color: #232324;
}

.itemcolor {
  background-image: linear-gradient(-20deg, #b8d0e8, #08052b) !important;
}

.nav-item:hover {
  color: #b8d0e8;
  width: "100%";
}

.items-menu {
  color: #fff;
}

li a.dropdown-toggle {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  display: inline-flex;
}

.sidebar-header > span {
  position: relative;
  float: right;
  padding: -0.5em;
  margin-right: 7px;
  font-size: 2rem;
  cursor: pointer;
}
.side-menu {
  /* height: calc(100vh - 130px); */
  height: 68vh;
  /* height: 27vh; */
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 1px;
  scroll-behavior: auto;
}

.side-menu::-webkit-scrollbar-thumb {
  border-radius: 3px;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  min-height: 100vh;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  body {
    overflow: scroll;
  }

  .sidebar {
    min-width: 150px;
    max-width: 150px;
    background-image: linear-gradient(-20deg, #0f2535, #1b2430) !important;
    min-height: 100vh;
    color: #fff;
    margin-left: -150px;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 5%;
    max-width: 100%;

    min-height: 100vh;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 20%;
  }
  .side-menu {
    /* height: calc(175vh - 130px); */
    height: 68vh;
    overflow-y: scroll;
    padding-left: -25px;
  }
}
/*----------------------------
      PAGE ICON
------------------------------ */
.page-icon {
  display: flex;
  color: #020f64;
  font-size: 30px;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: -10px;
}
.pagee-icon {
  display: flex;
  color: #020f64;
  font-size: 30px;
  justify-content: flex-start;
  margin-right: 20px;
  margin-bottom: -10px;
  margin-top: 10px;
}
.bl-icon {
  display: flex;
  color: #020f64;
  font-size: 30px;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: -10px;
}
.f-icon {
  display: flex;
  color: #020f64;
  font-size: 30px;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: -10px;
}

.bc-icon {
  display: flex;
  color: #020f64;
  font-size: 30px;
  justify-content: flex-end;
  margin-right: 20px;
  margin-bottom: -10px;
}
.page-icon p {
  margin-left: 10px;
  font-weight: bold;
  height: fit-content;
}

#add-button {
  position: absolute;
  left: 300px;
  bottom: 30px;
  width: 56px;
  height: 56px;
}
